<template>
  <v-app id="inspire">

    <v-app-bar app dark src="../assets/Top_Bar-03.svg">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        v-if="page != 'PerfilDetalhes'"
      ></v-app-bar-nav-icon>
      <v-spacer />
      <v-toolbar-title class="lign-center text-center">{{ $t('student.student_portal') }}<br>{{page}}</v-toolbar-title>
      <v-spacer />
      <v-btn icon disabled> </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-sheet>
        <v-img
          class="pa-4"
          :src="profile.cover"
          max-height="130px"
          position="right center"
        >
          <v-avatar size="70">
            <v-img
              class="rounded-circle"
              :src="profile.avatar"
              aspect-ratio="1"
            ></v-img>
          </v-avatar>
          <div class="pt-2 font-weight-medium white--text">
            {{ profile.student.user }}
          </div>
        </v-img>
      </v-sheet>
      <v-divider></v-divider>
      <v-list>
        <v-list-item-group color="blue">
          <v-list-item
          @click="goSoftware()"
          >
            <v-list-item-icon>
              <v-icon>mdi-download</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t('home.home_titlesoftware') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="[icon, text, router] in links"
            :key="icon"
            @click="
              drawer = false;
              //page = text;
              openRouter(router)
            "
            link
          >
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <div class="logoutStyle">
          <v-list-item
            @click="
              logoutDialog = true;
              drawer = false;
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('botoes.botao_sair') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-dialog v-model="logoutDialog" persistent max-width="320">
      <v-card elevation="15" class="rounded-xl py-4 px-2">
        <v-img src="../assets/HelloCircle2.svg" width="85px" class="mx-auto" />
        <v-card-title style="font-size: 1.1rem" class="mt-n3">
          {{ $t('admin.admin_sair.sair1') }}
        </v-card-title>
        <v-card-text style="font-size: 0.9rem" class="mt-n2">
          {{ $t('admin.admin_sair.sair2') }}
        </v-card-text>
        <v-card-actions class="mt-n2">
          <v-row no-gutters justify="center">
            <v-col cols="12" class="pb-3">
              <v-btn
                rounded
                color="darkblue"
                large
                dark
                block
                @click="logout()"
              >
                {{ $t('botoes.botao_sair') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                outlined
                rounded
                large
                color="darkblue"
                dark
                block
                @click="logoutDialog = false"
              >
                {{ $t('botoes.botao_cancelar') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="firstAccess"
      persistent
      :max-width="$vuetify.breakpoint.lgAndUp ? '60%' : '90%'"
      :fullscreen="$vuetify.breakpoint.xsOnyl"
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">
          {{ $t('label.label_senha.senha_redefinir') }}
        </v-card-title>
        <v-card-text class="pt-6">
          <v-form ref="editPass">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  :label="$t('label.label_celular.celular_inserir')"
                  color="darkblue"
                  append-icon="mdi-cellphone"
                  v-mask="'(##) #########'"
                  outlined
                  v-model="phone"
                  :rules="[rules.required, rules.phoneLength(13)]"
                  class="px-2"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="px-2">
                <v-text-field
                  color="darkblue"
                  :label="$t('label.label_senha.senha_nova')"
                  outlined
                  :rules="[rules.required, rules.length(4)]"
                  :type="showPassword1 ? 'text' : 'password'"
                  :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword1 = !showPassword1"
                  :placeholder="$t('label.label_senha.senha_inserasenha')"
                  v-model="password"
                />
              </v-col>
              <v-col cols="12" sm="6" class="px-2">
                <v-text-field
                  color="darkblue"
                  :label="$t('label.label_senha.senha_confirmar')"
                  outlined
                  :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                  :type="showPassword2 ? 'text' : 'password'"
                  :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword2 = !showPassword2"
                  :placeholder="this.$t('label.label_senha.senha_confirmarsua')"
                  v-model="confirmPassword"
                />
              </v-col>
              <v-row no-gutters>
                <v-checkbox v-model="enabled" color="darkblue">
                  <template v-slot:label>
                    <div>
                      {{ $t('hometeacher.hometeacher_termo.termo1') }}
                      <a
                        target="_blank"
                        href="https://makereducacional.com.br/termsofuse"
                        class="darkblue--text"
                        @click.stop
                      >
                       {{ $t('hometeacher.hometeacher_termo.termo2') }}
                      </a>
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-4 pb-4 mt-n6">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            color="darkpink"
            class="white--text px-4 ml-2"
            @click="editPassword()"
            :disabled="!enabled"
          >
            {{ $t('botoes.botao_alterar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="errorSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="error"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="error"
        left
        >mdi-close-circle
      </v-icon>
      <span class="black--text text-subtitle-1">
        Serial inválido !
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="errorSnackbar = null"
          rounded
          color="error"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-model="SerialAccess"
      persistent
      :max-width="$vuetify.breakpoint.lgAndUp ? '60%' : '90%'"
      :fullscreen="$vuetify.breakpoint.xsOnyl"
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">
         Coloque seu Serial
        </v-card-title>
        <v-card-text class="pt-6">
          <v-form ref="inserirSerial">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  label="Serial"
                  color="darkblue"
                  outlined
                  v-model="serial"
                  :rules="[rules.required, rules.phoneLength(12)]"
                  class="px-2"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-4 pb-4 mt-n6">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            color="darkpink"
            class="white--text px-4 ml-2"
            @click="inserirSerial()"
          >
            Inserir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-main class="backcolor">
      <!-- Aqui -->
      <StudentCourse v-if="page == $t('admin.admin_menu.menu3')" />
      <StudentForum v-if="page == $t('admin.admin_menu.menu32')" :teamId="profile.student.teamId" :courseId="profile.student.team.courseId"  />
      <StudentClass v-if="page == $t('admin.admin_menu.menu18')" />
      <StudentProfile v-if="page == $t('botoes.botao_perfil')" />
      <StudentGame v-if="page == $t('admin.admin_menu.menu19')" />
      <StudentEvents v-if="page == $t('admin.admin_menu.menu7')" />
      <StudentGrades v-if="page == $t('admin.admin_menu.menu20')" />
      <StudentDetails v-if="page == $t('teams.team_tab.tab1')" />
      <StudentOtherCourse v-if="page == $t('admin.admin_menu.menu2')" />
      <StudentProfileFriend v-if="page == $t('admin.admin_menu.menu21')" />
      <StudentOtherCourseClasses v-if="page == $t('admin.admin_menu.menu22')" />
      <StudentProfileDetails v-if="page == $t('admin.admin_menu.menu23')" />
      <StudentMaterialExtra v-if="page == $t('admin.admin_menu.menu24')" />
      <router-view @message="setMessage" />
    </v-main>

  </v-app>
</template>

<script>
import StudentCourse from "../components/Student/StudentCourse.vue";
import StudentClass from "../components/Student/StudentClass.vue";
import StudentProfile from "../components/Student/StudentProfile.vue";
import StudentGame from "../components/Student/StudentGame.vue";
import StudentEvents from "../components/Student/StudentEvents.vue";
import StudentGrades from "../components/Student/Grades/StudentGrades.vue";
import StudentOtherCourse from "../components/Student/StudentOtherCourse.vue";
import StudentDetails from "../components/Student/Grades/StudentDetails.vue";
import StudentProfileFriend from "../components/Student/StudentProfileFriend.vue";
import StudentOtherCourseClasses from "../components/Student/StudentOtherCourseClasses.vue";
import StudentProfileDetails from "../components/Student/StudentProfileDetails.vue";
import StudentMaterialExtra from "../components/Student/StudentMaterial.vue";
import StudentForum from "../components/Forum/ForumTopics.vue";

const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");

window.addEventListener('popstate', function() {
  location.reload();
  //this.routerReload();
});

export default {
  name: "Student",
  data: () => ({
    gradeCourseId: null,
    enabled: false,
    emailNews: false,
    firstAccess: false,
    SerialAccess: false,
    errorSnackbar: false,
    showPassword1: false,
    showPassword2: false,
    phone: null,
    serial: null,
    rules: {
      length: (len) => (v) =>
        (v || "").length >= len ||
        `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
      phoneLength: (len) => (v) =>
        (v || "").length >= len ||
        `${this.$t('regras.regra_telefone.telefone1')} ${len - 5} ${this.$t('regras.regra_telefone.telefone2')}`,
      required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
    },
    confirmPasswordRules: [(v) => !!v || this.$t('regras.regra_obrigatorio')],
    password: "",
    confirmPassword: "",
    drawer: null,
    logoutDialog: false,
    links: [],
    page: "",
    exercisesByClassId: null,
    profile: { student: { user: "Aluno Maker Educacional" } },
    friend: {},
    courseId: null,
    toclassOtherCourse: false,
  }),
  components: {
    StudentCourse,
    StudentClass,
    StudentProfile,
    StudentGame,
    StudentEvents,
    StudentGrades,
    StudentOtherCourse,
    StudentDetails,
    StudentProfileFriend,
    StudentOtherCourseClasses,
    StudentProfileDetails,
    StudentMaterialExtra,
    StudentForum,
  },
  mounted() {
    document.title = this.$t('student.student_portal');
    this.links = [
      ["mdi-book-open-variant", this.$t('admin.admin_menu.menu3'), "class"], //Revisão, Atividades e Extras por aula.
      ["mdi-forum", this.$t('admin.admin_menu.menu32'), "forum"], //Fórum.
      ["mdi-gamepad-variant", this.$t('admin.admin_menu.menu19'), "atividade"], //Cursos extras, questionários, quizes, vídeos etc.
      ["mdi-school", this.$t('admin.admin_menu.menu20'), "progresso"], //Resultado do questionário das aulas e progresso em outras atividades.
      ["mdi-bag-personal", this.$t('admin.admin_menu.menu2'), "curso"], //Tela com cursos extras.
      ["mdi-archive", this.$t('admin.admin_menu.menu24'), "materialExt"], //Materiais cadastrados pelo professor
      ["mdi-star", this.$t('admin.admin_menu.menu7'), "eventos"], // Atividades do aluno e do portal
      ["mdi-account", this.$t('botoes.botao_perfil'), "perfil"], // Alterar dados do usuário
    ]
    this.page = this.$t('admin.admin_menu.menu3')
    this.routerReload()
  },
  created() {
    this.getProfile();
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirmPassword || this.$t('login.login_painel.coincidem');
    },
  },
  methods: {
    openRouter(router){
      console.log(router)
      if(router == "class"){
        this.$router.push("/student")
      }
      else if(router == "forum"){
        this.$router.push("/student/Forum")
      }
      else if(router == "atividade"){
        this.$router.push("/student/Atividades")
      }
      else if(router == "progresso"){
        this.$router.push("/student/Progresso")
      }
      else if(router == "curso"){
        this.$router.push("/student/Cursos")
      }
      else if(router == "materialExt"){
        this.$router.push("/student/MaterialExt")
      }
      else if(router == "eventos"){
        this.$router.push("/student/Events")
      }
      else if(router == "perfil"){
        this.$router.push("/student/Perfil")
      }
      this.routerReload()
    },

    routerReload(){
    if(this.$route.path == '/student/Forum'){
      this.page = this.$t('admin.admin_menu.menu32')
    }
    else if(this.$route.path == '/student/Atividades'){
      this.page = this.$t('admin.admin_menu.menu19')
    }
    else if(this.$route.path == '/student/Progresso'){
      this.page = this.$t('admin.admin_menu.menu20')
    }
    else if(this.$route.path == '/student/Progresso/Detalhas'){
      this.gradeCourseId = parseInt(localStorage.getItem("gradeCourseId"))
      this.page = this.$t('teams.team_tab.tab1')
    }
    else if(this.$route.path == '/student/Cursos'){
      this.page = this.$t('admin.admin_menu.menu2')
    }
    else if(this.$route.path == '/student/MaterialExt'){
      this.page = this.$t('admin.admin_menu.menu24')
      //localStorage.setItem("licensed", this.teacherInfo.school.licensed);
    }
    else if(this.$route.path == '/student/Events'){
      this.page = this.$t('admin.admin_menu.menu7')
    }
    else if(this.$route.path == '/student/Perfil'){
      this.page = this.$t('botoes.botao_perfil')
      //this.trainingId = parseInt(localStorage.getItem("id"))
    }
    else if(this.$route.path == '/student/Perfil/Friends'){
      this.page = this.$t('admin.admin_menu.menu21')
      this.friend = parseInt(localStorage.getItem("friend"))
    }
    else if(this.$route.path == '/student/Perfil/Edit'){
      this.page = this.$t('admin.admin_menu.menu23')
    }
    else{
      this.page = this.$t('admin.admin_menu.menu3')
    }},

    changeMenuLicensed() {
      if (this.profile.student.school.licensed) {
        this.links.splice(0, 1);
        this.links.splice(1, 1);
        this.page = this.$t('admin.admin_menu.menu2');
      }
    },
    async getProfile() {
        let profile = await axios.get(`${url}/profilesForStudent`, {
          headers: {
            authorization: token,
          },
        });
        if (profile.data == null) {
          this.firstAccess = true;
          profile.data = {
            avatar:
              "http://www.makereducacional.com.br/astronaut/avatars/Astronaut.svg",
            cover:
              "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_BluePattern.svg",
            student: { user: this.$t('student.student_makereducation') },
          };
        }
        this.profile = profile.data;
        console.log(this.profile)

        if (
          this.profile.student.acceptedTerms == null ||
          this.profile.student.acceptedTerms == 0
        ) {
          this.firstAccess = true;
        }
        if(
          this.profile.student.team.Course.serial == 1
        ){
          await this.getSerial()
            // this.SerialAccess = true
        }
        this.changeMenuLicensed();
    },

    async getSerial(){
      let serial = await axios.get(`${url}/serial/${this.profile.student.team.Course.id}/${this.profile.student.id}`, {
          headers: {
            authorization: token,
          },
        });
        if(serial.data.length == 0){
          this.SerialAccess = true
        }
    },

    goSoftware(){
      window.open("https://makereducacional.com.br/software");;
    },

    setMessage(msg) {
      this.page = msg;
    },

    toLogin() {
      this.$router.go("/login");
    },

    close() {
      // destroy the vue listeners, etc
      this.$destroy();
      // remove the element from the DOM
    },

    logout() {
      this.logoutDialog = false;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("profile");
      localStorage.removeItem("gradeCourseId");
      localStorage.removeItem("licensed");
      this.toLogin();
    },

    // async authorization() {
    //   try {
    //     let profil = await axios.get(`${url}/profilesForStudent`, {
    //       headers: {
    //         authorization: token,
    //       },
    //     })
    //     console.log(profil)
    //   } catch (error) {
    //     console.error('Logout failed:', error);
    //     this.logoutDialog = false;
    //   localStorage.removeItem("accessToken");
    //   localStorage.removeItem("profile");
    //   localStorage.removeItem("gradeCourseId");
    //   localStorage.removeItem("licensed");
    //   this.toLogin();
    //   }
    // },

    async inserirSerial() {
      let validation = this.$refs.inserirSerial.validate();

      if (validation) {
        let serialConf = await axios.get(`${url}/serialConfirma/${this.serial}/${this.profile.student.team.Course.id}`, {
          headers: {
              authorization: token,
            },
        })
        if(serialConf.data.length == 0){
          this.errorSnackbar = true
        }
        else {
          let data = {
          courseId: this.profile.student.team.Course.id,
          studentId: this.profile.student.id
          }
          await axios.put(`${url}/serialupdate/${this.serial}`, data, {
            headers: {
              authorization: token,
            },
          });
          this.SerialAccess = false
        }

        // console.log(serial)
      }

      // if (validation) {
      //   try {
      //     let data = {
      //       phone: this.phone,
      //       password: this.confirmPassword,
      //       acceptedTerms: this.enabled,
      //     };

      //     await axios.post(`${url}/students/updatePassword`, data, {
      //       headers: {
      //         authorization: token,
      //       },
      //     });

      //     await axios.post(
      //       `${url}/profilesForStudent`,
      //       {
      //         avatar:
      //           "http://www.makereducacional.com.br/astronaut/avatars/Astronaut.svg",
      //         cover:
      //           "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_BluePattern.svg",
      //       },
      //       {
      //         headers: {
      //           authorization: token,
      //         },
      //       }
      //     );
      //     this.getProfile();
      //     this.firstAccess = false;
      //   } catch (error) {
      //     console.error(error);
      //   }
      // }
    },

    async editPassword() {
      let validation = this.$refs.editPass.validate();

      if (validation) {
        try {
          let data = {
            phone: this.phone,
            password: this.confirmPassword,
            acceptedTerms: this.enabled,
          };

          await axios.post(`${url}/students/updatePassword`, data, {
            headers: {
              authorization: token,
            },
          });

          await axios.post(
            `${url}/profilesForStudent`,
            {
              avatar:
                "http://www.makereducacional.com.br/astronaut/avatars/Astronaut.svg",
              cover:
                "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_BluePattern.svg",
            },
            {
              headers: {
                authorization: token,
              },
            }
          );
          this.getProfile();
          this.firstAccess = false;
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
};
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 8px;
}

.v-navigation-drawer__content {
  width: 103%;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f128;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(121, 121, 121);
}

.logoutStyle {
  bottom: 0px;
  width: 100%;
}

a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
