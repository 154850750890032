<template>
  <v-app class="backcolor">
    <v-row no-gutters>
      <div class="d-flex align-self-center astronaut" v-if="hasContent != 0">
        <v-img
          v-if="$vuetify.breakpoint.lgAndUp"
          src="../../assets/Astronaut.svg"
          width="230px"
        >
        </v-img>
      </div>
      <v-col cols="11" lg="8" class="mx-auto my-6">
        <v-card class="rounded-xl py-8 mb-10 maincard" elevation="5">
          <v-row no-gutters justify="center" align="center">
            <span
              class="blue font-weight-medium px-6 mb-4 white--text"
              style="
                font-size: 1.3rem;
                border-radius: 15px;
                text-transform: uppercase;
              "
            >
              {{ $t('events.events_proximo') }}
            </span>
          </v-row>

          <v-col
            v-for="event in eventsArray"
            :key="event.id"
            cols="11"
            class="mx-auto"
          >
            <v-card
              class="mx-auto my-1 rounded-xl"
              max-width="600"
              elevation="5"
            >
              <v-card-title
                class="text-subtitle-1 text-sm-h6 darkblue white--text"
              >
                {{ event.title }}
              </v-card-title>
              <v-card-text class="pa-0">
                <v-img max-height="250" :src="event.thumbnail"></v-img>
              </v-card-text>
              <v-card-actions class="align-center mx-2">
                <div class="black--text py-2 font-weight-medium">
                  <v-icon class="yellow pa-1 white--text rounded-lg">
                    mdi-calendar
                  </v-icon>
                  {{ event.date }}
                </div>
                <v-spacer></v-spacer>

                <!-- Verificação se o evento já passou -->
                <template v-if="isFutureDate(event.date)">
                  <v-dialog transition="dialog-bottom-transition" max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="darkpink px-2 px-sm-4 rounded-xl"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ $t('botoes.botao_saiba') }}
                      </v-btn>
                    </template>
                    <template>
                      <v-card class="pb-2 rounded-xl" elevation="15">
                        <v-img max-height="250" :src="event.thumbnail"></v-img>
                        <v-card-text class="py-2">
                          <div
                            class="text-subtitle-1 text-sm-h5 black--text font-weight-bold"
                          >
                            {{ event.title }}
                          </div>
                          <div class="text-subtitle-1 black--text font-weight-medium">
                            {{ event.date }}
                          </div>
                          <div class="mt-4 text-justify text-body-2">
                            {{ event.content }}
                          </div>
                        </v-card-text>
                        <v-card-actions v-if="event.link" class="justify-center mt-4">
                          <v-btn
                            :href="event.link"
                            target="_blank"
                            class="darkpink white--text rounded-xl"
                          >
                            {{ $t('botoes.botao_inscrever') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </template>

                <!-- Mensagem para eventos passados -->
                <template v-else>
                  <div class="red--text py-2 font-weight-medium">
                    <v-icon class="mdi mdi-alert-circle-outline red--text"></v-icon>
                    Evento já realizado
                  </div>
                </template>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col
            v-if="hasContent === 0"
            cols="12"
            sm="11"
            class="mx-auto"
            align-self="center"
          >
            <v-img
              src="../../assets/NoEvent.svg"
              :max-width="$vuetify.breakpoint.smAndUp ? '40%' : '100%'"
              class="mx-auto"
            ></v-img>
            <div class="text-h5 text-sm-h4 text-center font-weight-medium">
              <span> {{ $t('alerta.alerta_evento.evento_nenhum') }} </span>
            </div>
            <div
              class="
                text-sm-h6
                px-sm-16
                text-center
                font-weight-normal
                text--secondary
              "
            >
              <span>
                {{ $t('alerta.alerta_evento.evento_novo') }}
              </span>
            </div>
          </v-col>
        </v-card>
      </v-col>
      <v-col
        cols="auto"
        class="d-flex flex-column"
        v-if="$vuetify.breakpoint.lgAndUp"
      >
        <div>
          <v-img
            v-if="hasContent != 0"
            src="../../assets/Moon.svg"
            width="170px"
            class="moon"
          >
          </v-img>
        </div>
        <div class="mt-auto">
          <v-img
            v-if="hasContent != 0"
            src="../../assets/Rocket.svg"
            width="300px"
            class="rocket"
          >
          </v-img>
        </div>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");

export default {
  data() {
    return {
      dialog: false,
      eventsArray: [],
      hasContent: null,
    };
  },
  methods: {
    getEvents() {
      axios
        .get(`${url}/eventsForStudent`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.hasContent = res.data.length;
          this.eventsArray = res.data;
          this.eventsArray.forEach((element, index) => {
            this.eventsArray[index].date = this.showDate(element.date);
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    showDate(dateDB) {
      if (!dateDB) return null;

      const [date] = dateDB.split("T");
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    isFutureDate(eventDate) {
      const today = new Date();
      const [day, month, year] = eventDate.split('/').map(Number); // Extrai ano, mês e dia da string
      const event = new Date(year, month - 1, day); // Cria a data do evento

      // Set both dates to midnight to compare only the date (ignoring time)
      today.setHours(0, 0, 0, 0);

      return event >= today; // Compara a data do evento com hoje
    }
  },
  mounted() {
    this.getEvents();
  },
};
</script>

<style lang="css" scoped>
.maincard {
  z-index: 2;
}

.astronaut {
  position: absolute;
  z-index: 0;
  left: 6%;
  transform: rotate(340deg);
  animation: astro 3s alternate infinite;
}

@keyframes astro {
  0% {
    transform: rotate(350deg);
  }

  60% {
    transform: rotate(340deg);
  }

  100% {
    transform: rotate(350deg);
  }
}

.moon {
  position: absolute;
  z-index: 1;
  right: 2%;
  top: 3%;
  animation: moonRotate 100s linear infinite;
}

@keyframes moonRotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rocket {
  z-index: 2;
  right: 200px;
  bottom: 10px;
  position: absolute;
  animation: bounce 5s alternate infinite;
}

@keyframes bounce {
  0%,
  30%,
  60%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  20%,
  80% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
</style>
