<template>
  <v-app class="backcolor">
    <v-container fluid>
      <v-btn
        v-if="hasClass !== 0 && files.length !== 0"
        fixed
        right
        fab
        absolute
        x-large
        color="darkcolor"
        style="bottom: 40px; right: 40px"
        class="elevation-24"
        @click="fileDialog = true"
      >
        <v-icon color="white">mdi-folder-multiple</v-icon>
      </v-btn>
      <v-row class="px-5">
        <!-- <v-col
          v-for="(lessons, index) in classes"
          :key="lessons.Class.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          class="py-6"
        >
          <v-row justify="center">
            <v-sheet
              :color="colorLookDark(index)"
              class="text-center rounded-t-xl"
              width="70%"
              height="35"
              dark
              elevation="2"
            >
              Aula {{ index + 1 }}
            </v-sheet>
          </v-row>
          <div class="oneClass">
            <v-sheet
              height="150"
              :color="colorLookLight(index)"
              rounded
              elevation="5"
              @click="classDetails(lessons.Class)"
            >
              <v-row class="pl-4">
                <v-col cols="10" class="pr-0">
                  <v-card rounded elevation="0">
                    <v-img
                      max-height="125"
                      lazy-src="http://www.makereducacional.com.br/images/Curso Padrão PT.png"
                      :src="
                        lessons.Class.tumbnail == null
                          ? lessons.Course.thumbnail
                          : lessons.Class.tumbnail
                      "
                      :aspect-ratio="9 / 16"
                    ></v-img>
                  </v-card>
                </v-col>
                <v-col cols="2" align="center" class="pl-0">
                  <div v-for="(n, i) in classesMoments" :key="i">
                    <v-icon
                      class="pb-6"
                      :color="n[3] == true ? '#468C00' : ''"
                      style="text-shadow: 0.5px 0.5px #0000003d"
                      >{{ n[0] }}</v-icon
                    >
                  </div>
                </v-col>
                <v-col cols="2" align="center" class="pl-0">
                  <div>
                    <v-icon
                      class="pb-6"
                      style="text-shadow: 0.5px 0.5px #0000003d"
                      :color="lessons.Class.review == true ? '#468C00' : ''"
                    >
                      mdi-book-open-page-variant
                    </v-icon>
                  </div>
                  <div>
                    <v-icon
                      class="pb-6"
                      style="text-shadow: 0.5px 0.5px #0000003d"
                      :color="lessons.Class.exercise == true ? '#468C00' : ''"
                    >
                      mdi-head-cog
                    </v-icon>
                  </div>
                  <div>
                    <v-icon
                      class="pb-6"
                      style="text-shadow: 0.5px 0.5px #0000003d"
                      :color="lessons.Class.activity == true ? '#468C00' : ''"
                    >
                      mdi-gamepad
                    </v-icon>
                  </div>
                </v-col>
              </v-row>
            </v-sheet>
          </div>
        </v-col> -->

        <v-col
          v-for="lessons in classes"
          :key="lessons.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-card class="mx-auto rounded-lg" @click="classDetails(lessons)">
            <v-img
              max-height="180"
              lazy-src="http://www.makereducacional.com.br/images/Curso Padrão PT.png"
              :src="
                lessons.tumbnail == null ? lessons.thumbCurso : lessons.tumbnail
              "
              :aspect-ratio="9 / 16"
            />

            <v-card-actions
              class="font-weight-bold px-2 px-md-4 darkblue white--text"
            >
              {{ lessons.title }}
              <!-- 
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="lessons.progressIcon.color"
                    v-bind="attrs"
                    v-on="on"
                    size="1.75rem"
                  >
                    {{ lessons.progressIcon.icon }}
                  </v-icon>
                </template>
                <span> {{ lessons.progressIcon.text }}</span>
              </v-tooltip> -->
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col v-if="hasClass == 0 && files.length !== 0">
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            v-for="(file, index) in files"
            :key="file.id"
          >
            <v-card
              :href="file.uri"
              target="_blank"
              class="pa-0 flat white--text"
              height="150"
            >
              <v-alert
                :icon="
                  $vuetify.breakpoint.xsOnly ? false : 'mdi-folder-multiple'
                "
                :prominent="!$vuetify.breakpoint.xsOnly"
                height="100%"
                class="d-flex align-center font-weight-medium text-center"
                style="font-size: 1.4rem"
                :color="colorLookLight(index)"
                text
                outlined
              >
                <span class="font-weight-bold black--text">
                  {{ file.description }}
                </span>
              </v-alert>
            </v-card>
          </v-col>
        </v-col>

        <v-col
          v-if="hasClass === 0 && files.length === 0"
          cols="12"
          sm="11"
          class="mx-auto"
          align-self="center"
        >
          <v-img
            src="../../assets/History.svg"
            :max-width="$vuetify.breakpoint.smAndUp ? '35%' : '100%'"
            class="mx-auto my-4"
          ></v-img>
          <div class="text-h5 text-sm-h4 text-center font-weight-medium">
            <span>{{ $t('alerta.alerta_auladisponivel') }}</span>
          </div>
          <div
            class="
              text-sm-h6
              px-sm-16
              text-center
              font-weight-normal
              text--secondary
            "
          >
            <span>{{ $t('alerta.alerta_recarregue') }}</span>
          </div>
        </v-col>
      </v-row>

      <v-dialog
        v-model="classDialog"
        persistent
        transition="dialog-top-transition"
        width="70%"
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <v-card class="rounded-lg">
          <v-card-title
            class="font-weight-medium text-subtitle-1 darkblue white--text"
          >
            {{ $t('student.student_detalheresaula') }}
            <v-spacer />
            <v-btn
              dark
              icon
              @click="
                classDialog = false;
                alert = false;
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text
            class="px-4 px-lg-12 py-4 py-sm-8 py-md-10 d-flex"
            :class="$vuetify.breakpoint.smAndUp ? 'flex-row' : 'flex-column'"
          >
            <div class="text-center mb-2">
              <v-avatar
                :size="$vuetify.breakpoint.mdAndUp ? 260 : 200"
                tile
                class="rounded-xl mx-auto"
              >
                <v-img :src="thumb" />
              </v-avatar>
            </div>

            <div
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'font-size: 1.2rem'
                  : 'font-size: 1rem'
              "
              class="mt-4"
              :class="$vuetify.breakpoint.smAndUp ? 'ml-6' : ''"
            >
              <div
                :style="
                  $vuetify.breakpoint.mdAndUp
                    ? 'font-size: 1.6rem'
                    : 'font-size: 1.2rem'
                "
                class="font-weight-black"
              >
                {{ title }}
              </div>

              <div class="mt-4"><strong>{{ $t('homework.homework_detalhes.detalhes1') }}</strong> {{ project }}</div>
              <div><strong>{{ $t('homework.homework_detalhes.detalhes2') }}</strong> {{ kit }}</div>
              <div><strong>{{ $t('homework.homework_detalhes.detalhes3') }}</strong> {{ description }}</div>

              <div
                class="mt-4 mt-md-10 mt-lg-16"
                :class="$vuetify.breakpoint.smAndUp ? '' : 'text-center'"
              >
                <v-btn
                  dark
                  color="darkpink"
                  rounded
                  class="px-8"
                  @click="toClass(idClass)"
                >
                  <v-icon left> mdi-play-outline </v-icon>
                  {{ $t('botoes.botao_inicia') }}
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="fileDialog"
        :max-width="$vuetify.breakpoint.mdAndUp ? '45%' : '90%'"
      >
        <v-card class="rounded-lg">
          <v-card-title
            class="
              darkblue
              white--text
              font-weight-bold
              d-flex
              justify-space-between
            "
            style="font-size: 1.6rem"
          >
            <span>{{ $t('homework.homework_tab.tab2') }}</span>
            <v-btn icon dark @click="fileDialog = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text class="pt-2 pb-6 px-6">
            <v-row class="mt-1">
              <v-col
                cols="12"
                sm="6"
                v-for="(file, index) in files"
                :key="file.id"
              >
                <v-card
                  :href="file.uri"
                  target="_blank"
                  class="pa-0 flat white--text"
                  height="100"
                >
                  <v-alert
                    height="100%"
                    class="
                      d-flex
                      align-center
                      justify-center
                      font-weight-medium
                      text-center
                    "
                    style="font-size: 1.3rem"
                    :color="colorLookLight(index)"
                    text
                    outlined
                  >
                    <span class="font-weight-bold black--text">
                      {{ file.description }}
                    </span>
                  </v-alert>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
// import ProfilePictures from "./ProfilePictures";
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");

export default {
  name: "StudentClasses",
  data: () => ({
    classes: [],
    classesMoments: [],
    rows: 1,
    slots: 1,
    rating: [],
    classDialog: false,
    files: {},
    fileDialog: false,
    alert: false,
    lastLesson: 1,
    actualMonth: 0,
    classColors: [
      { light: "pink ", dark: "darkpink" },
      { light: "cyan", dark: "darkcyan" },
      { light: "yellow", dark: "darkyellow" },
    ],
    title: "",
    description: "",
    thumb: "",
    project: "",
    kit: "",
    idClass: null,
    hasClass: null,
    progressValue: null,
  }),
  created() {
    this.getClasses();
    this.toTop();
  },
  mounted() {
      this.classesMoments = [
      {
        text: "review",
        icon: "mdi-book-open-page-variant",
        content: this.$t('student.student_classesmoments.classesmoments1'),
        color: "cyan",
        status: false,
      },
      {
        text: "exercises",
        icon: "mdi-head-cog",
        content: this.$t('student.student_classesmoments.classesmoments2'),
        color: "pink",
        status: false,
      },
      {
        text: "activity",
        icon: "mdi-gamepad",
        content: this.$t('student.student_classesmoments.classesmoments3'),
        color: "yellow",
        status: false,
      }
    ],
    this.rows = Math.ceil(this.classes.length / 5);
    this.slots = this.classes.length;
    this.actualMonth = Math.ceil(this.lastLesson / 4);
  },
  methods: {
    toTop() {
      window.scrollTo(0, 0);
    },
    async getFilesCourse(id) {
      try {
        let res = await axios.get(`${url}/filesCourses/byCourse/${id}`, {
          headers: {
            authorization: token,
          },
        });
        this.files = res.data;
      } catch (error) {
        console.error(error);
      }
    },
    getClasses() {
      let id = this.$parent.$parent.$parent.courseId;

      this.getFilesCourse(id);
      axios
        .get(`${url}/classesForStudent/${id}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.hasClass = res.data.length;
          this.classes = res.data;
          console.log(this.classes);
          // this.checkStep();
          this.$forceUpdate();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    colorLookLight(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].light;
      return color;
    },
    colorLookDark(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].dark;
      return color;
    },
    classDetails(classes) {
      this.classDialog = true;
      setTimeout(() => {
        this.alert = true;
      }, 150);

      this.title = classes.title;
      this.description = classes.description;
      this.project = classes.project;
      this.kit = classes.kit;
      this.thumb = classes.tumbnail;
      if (this.thumb == null) this.thumb = classes.thumbCurso;
      this.idClass = classes.id;
    },
    toClass(exercisesByClass) {
      this.$parent.$parent.$parent.exercisesByClassId = exercisesByClass;
      this.checkProgress(exercisesByClass);
      this.$emit("message", this.$t('admin.admin_menu.menu18'));
      this.classDialog = false;
      this.alert = false;
      this.$parent.$parent.$parent.toclassOtherCourse = true;
      this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu18');
    },
    async checkProgress(id) {
      let progress = await axios.get(`${url}/progressLessons?classes=${id}`, {
        headers: {
          authorization: token,
        },
      });

      if (progress.data.length == 0) {
        let body = {
          classId: id,
          review: false,
          exercise: false,
          activity: false,
        };
        await axios.post(`${url}/progressLessons`, body, {
          headers: {
            authorization: token,
          },
        });
      }
    },
    // checkStep() {
    //   const verifyStage = {
    //     "0-0-1": {
    //       icon: "mdi-check-decagram",
    //       color: "light-green accent-4",
    //       text: "Aula Concluída",
    //     },
    //     "0-0-0": {
    //       icon: "mdi-clock-outline",
    //       color: "blue-grey lighten-4",
    //       text: "Aula Pendente",
    //     },
    //     "1-1-1": {
    //       icon: "mdi-check-decagram",
    //       color: "light-green accent-4",
    //       text: "Aula Concluída",
    //     },
    //     "1-0-1": {
    //       icon: "mdi-progress-check",
    //       color: "blue-grey lighten-4",
    //       text: "Aula em Progresso",
    //     },
    //     "1-1-0": {
    //       icon: "mdi-progress-check",
    //       color: "yellow",
    //       text: "Aula em Progresso",
    //     },
    //     "1-0-0": {
    //       icon: "mdi-clock-outline",
    //       color: "blue-grey lighten-4",
    //       text: "Aula Pendente",
    //     },
    //   };

    //   for (let i = 0; i < this.classes.length; i++) {
    //     const element = this.classes[i];
    //     const key = `${element.hasExercise}-${element.exercise}-${element.applied}`;
    //     const result = verifyStage[key] || "err";

    //     this.classes[i].progressIcon = result;
    //     console.log(this.classes[i].progressIcon);
    //   }
    // },
  },
};
</script>

<style>
.oneClass {
  position: relative;
}
</style>
