<template>
  <div>
    <v-tabs
      v-model="tab"
      background-color="blue"
      centered
      :icons-and-text="$vuetify.breakpoint.smAndUp"
      dark
      :height="showTab ? '' : 1"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        v-for="(n, i) in tabs"
        :key="i"
        :href="`#${n.id}`"
        style="text-x: none"
        @change="checkTab(n.id)"
      >
        <span v-if="$vuetify.breakpoint.smAndUp"> {{ n.title }} </span>
        <v-icon>{{ n.icon }}</v-icon>
      </v-tab>
    </v-tabs>

    <v-row no-gutters class="mt-6">
      <v-col cols="12" align-self="center">
        <v-tabs-items :value="tab" class="backcolor">

          <!-- APRESENTAÇAO -->
          <v-tab-item v-if="hasPresentation" value="1">
            <v-card
              class="pt-6 backcolor mx-auto"
              flat
              :width="$vuetify.breakpoint.smAndDonw ? '80%' : '60%'"
            >
              <v-carousel
                
                hide-delimiters
                :continuous="false"
                align="center"
                height="400"
              >
                <v-carousel-item v-for="pres in presentation" :key="pres.id">
                  <vimeo-player
                    ref="player"
                    :video-url="`https://player.vimeo.com/video/${pres.uri}?h=${pres.hash_parameter}`"
                    :player-width="$vuetify.breakpoint.xsOnly ? 320 : ''"
                    @ready="onReady"
                  />
                </v-carousel-item>
              </v-carousel>
            </v-card>
          </v-tab-item>

          <!-- MONTAGEM -->
          <v-tab-item v-if="hasMounting" value="2">
            <v-card
              flat
              class="backcolor d-flex justify-center align-center mx-auto"
              :max-width="$vuetify.breakpoint.smAndUp ? '70%' : '95%'"
            >
              <v-carousel
                hide-delimiters
                show-arrows-on-hover
                :continuous="false"
                :height="isPortrait ? 1200 : 500"
                @change="countAndVerifyMounting()"
              >
                <v-carousel-item
                  v-for="(mount, i) in mounting"
                  :key="i"
                  :src="mount"
                  class="cursor-lupa"
                  contain

                  @click="ampliarSlide()"
                ></v-carousel-item>
              </v-carousel>
            </v-card>
          </v-tab-item>
          <v-dialog v-model="bigslide">
            <v-carousel
                hide-delimiters
                :continuous="false"
                height="100%"
                
                @change="countAndVerifyMounting()"
              >
                <v-carousel-item
                  v-for="(mount, i) in mounting"
                  :key="i"
                  :src="mount"
                  contain
                ></v-carousel-item>
              </v-carousel>
            <!-- <v-img
             :src="selectSlide"
              height="100%"
             >
             </v-img> -->
          </v-dialog>
          <!-- RESUMO -->
          <v-tab-item v-if="hasSummaries" value="3">
            <v-card flat class="backcolor d-flex justify-center align-center">
              <v-img :src="summaries" class="rounded-xl" max-width="80vh" />
            </v-card>
          </v-tab-item>
          <!--EMED-->
          <v-tab-item v-if="hasEmbed" value="4">
            <v-card
              class="pt-6 backcolor mx-auto"
              flat
              :width="$vuetify.breakpoint.smAndDonw ? '80%' : '60%'"
            >
            <div contenteditable="true"
              v-html="embed">
            </div>  
            </v-card>
          </v-tab-item>

          <!-- EXERCICIOS -->
          <v-tab-item v-if="hasExercises" value="5" :style="$vuetify.breakpoint.smAndDown ? 'height: 100vh' : ''">
            <v-window v-model="onboarding" touchless>
              <v-window-item
                v-for="(question, index) in exercises"
                :key="`card-${index}`"
              >
                <v-card flat class="px-5 px-sm-16 backcolor">
                  <v-row class="py-2" align="center" justify="center">

                    <!-- MULTIPLA ESCOLHA -->
                    <div v-if="question.type == 'M'" class="pa-2 text-center">
                      <div>
                        <span
                          class="white--text font-weight-black px-5 py-1 blue"
                          style="font-size: 1.2rem; border-radius: 10px"
                        >
                          {{ $t('student.student_exercicio') }} {{ index + 1 }}
                        </span>
                      </div>
                      <v-row class="mx-md-16 my-2">
                        <v-col
                          cols="12"
                          v-if="!!question.content"
                          class="text-center"
                        >
                          <div class="text-body-2 text-sm-subtitle-1">
                            {{ question.content }}
                          </div>
                        </v-col>
                        <v-col cols="12" v-if="!!question.image">
                          <div class="mb-4">
                            <v-img
                              :src="question.image"
                              max-width="80vh"
                              class="mx-auto rounded-lg"
                            >
                            </v-img>
                          </div>
                        </v-col>
                      </v-row>
                      <v-item-group
                        active-class="blue selected"
                        value="choosed"
                      >
                        <v-container>
                          <v-row>
                            <v-col
                              v-for="(opt, i) in question.option"
                              :key="i"
                              cols="12"
                              md="6"
                            >
                              <v-item v-slot="{ active, toggle }" :value="i">
                                <v-card
                                  class="
                                    d-flex
                                    align-center
                                    unselected
                                    px-4
                                    text-subtitle-2
                                    font-weight-medium
                                    text-center
                                  "
                                  :min-height="
                                    $vuetify.breakpoint.smAndUp ? 100 : 80
                                  "
                                  @click="toggle"
                                  v-on:click="
                                    multiSelected(opt.id, question.id, index, null)
                                  "
                                  style="
                                    background-color: rgba(249, 194, 40, 0.8);
                                  "
                                >
                                  <v-scroll-y-transition>
                                    <div
                                      v-if="active"
                                      class="flex-grow-1 white--text"
                                    >
                                      {{ opt.content }}
                                    </div>
                                    <div v-else class="flex-grow-1">
                                      {{ opt.content }}
                                    </div>
                                  </v-scroll-y-transition>
                                </v-card>
                              </v-item>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-item-group>
                    </div>

                    <!-- BINARIE -->
                    <div v-else-if="question.type == 'B'" class="pa-2 text-center">
                      <span
                        class="white--text font-weight-black px-5 py-1 blue"
                        style="font-size: 1.2rem; border-radius: 10px"
                      >
                        {{ $t('student.student_exercicio') }} {{ index + 1 }}
                      </span>
                      <div
                        v-if="!!question.content"
                        class="text-justify text-body-1 pt-4 px-2 px-md-16"
                      >
                        {{ question.content }}
                      </div>

                      <div v-if="!!question.image">
                        <v-img
                          :src="question.image"
                          max-width="80vh"
                          class="mx-auto rounded-lg mt-2"
                        >
                        </v-img>
                      </div>

                      <v-item-group
                        active-class="blue"
                        :class="!!question.image ? '' : 'mt-16'"
                      >
                        <v-container>
                          <v-row
                            class="px-16"
                            :class="!!question.image ? 'mt-2' : 'mt-16'"
                          >
                            <v-col
                              v-for="(bin, inx) in question.option"
                              :key="inx"
                              cols="12"
                              sm="6"
                              md="4"
                              class="mx-auto"
                            >
                              <v-item v-slot="{ active, toggle }">
                                <v-card
                                  class="d-flex align-center rounded-xl py-1"
                                  @click="toggle"
                                  v-on:click="
                                    multiSelected(bin.id, question.id, index, null)
                                  "
                                  style="
                                    background-color: rgba(249, 194, 40, 0.8);
                                  "
                                >
                                  <v-scroll-y-transition>
                                    <div
                                      v-if="active"
                                      class="
                                        text-h6
                                        flex-grow-1
                                        text-center
                                        white--text
                                      "
                                    >
                                      {{ bin.content }}
                                    </div>
                                    <div
                                      v-else
                                      class="text-h6 flex-grow-1 text-center"
                                    >
                                      {{ bin.content }}
                                    </div>
                                  </v-scroll-y-transition>
                                </v-card>
                              </v-item>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-item-group>
                    </div>

                    <!-- IMAGE -->
                    <div v-else-if="question.type == 'I'" class="pa-2 text-center">
                      <span
                        class="white--text font-weight-black px-5 py-1 blue"
                        style="font-size: 1.2rem; border-radius: 10px"
                      >
                        {{ $t('student.student_exercicio') }} {{ index + 1 }}
                      </span>
                      <div
                        v-if="!!question.content"
                        class="text-justify text-body-1 pt-4 px-md-16"
                      >
                        {{ question.content }}
                      </div>
                      <div v-if="!!question.image">
                        <v-img
                          :src="question.image"
                          max-width="80vh"
                          class="mx-auto rounded-lg mt-2"
                        >
                        </v-img>
                      </div>
                      <v-item-group>
                        <v-container>
                          <v-row class="px-16">
                            <v-col
                              v-for="(imge, ind) in question.option"
                              :key="ind"
                              cols="12"
                              sm="6"
                              class="mx-auto"
                            >
                              <v-item v-slot="{ active, toggle }">
                                <v-scroll-y-transition>
                                  <div v-if="active">
                                    <v-img
                                      :src="imge.content"
                                      class="selected mx-auto"
                                      max-width="300"
                                      :aspect-ratio="4 / 3"
                                    >
                                    </v-img>
                                  </div>
                                  <div v-else>
                                    <v-img
                                      :src="imge.content"
                                      max-width="300"
                                      class="unselected mx-auto"
                                      @click="toggle"
                                      v-on:click="multiSelected(imge.id, question.id, index, null)"
                                      :aspect-ratio="4 / 3"
                                    >
                                    </v-img>
                                  </div>
                                </v-scroll-y-transition>
                              </v-item>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-item-group>
                    </div>

                    <!-- DISSERTATIVO -->
                    <div v-else-if="question.type == 'D'" class="pa-2 text-center">
                      <span
                        class="white--text font-weight-black px-5 py-1 blue"
                        style="font-size: 1.2rem; border-radius: 10px"
                      >
                        {{ $t('student.student_exercicio') }} {{ index + 1 }}
                      </span>
                      <div
                        v-if="!!question.content"
                        class="text-justify text-body-1 pt-4 px-2 px-md-16"
                      >
                        {{ question.content }}
                      </div>

                      <div v-if="!!question.image">
                        <v-img
                          :src="question.image"
                          max-width="80vh"
                          class="mx-auto rounded-lg mt-2"
                        >
                        </v-img>
                      </div>

                      <v-container>
                        <v-row
                          class="px-5"
                          :class="!!question.image ? 'mt-2' : 'mt-16'"
                        >
                          <v-col
                            cols="12"
                            class="mx-auto"
                          >
                          <v-textarea
                            color="blue"
                            @input="multiSelected(question.option[0].id, question.id, index, $event)"
                            :label="$t('homework.homework_exercise.resposta_esperada_aluno')"
                            rows="4"
                            outlined
                            no-resize
                            persistent-hint
                            auto-grow
                          ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>


                  </v-row>
                </v-card>
              </v-window-item>

              <!-- CHECK ANSWERS -->
              <v-window-item class="mb-16">
                <div class="pa-2 text-center mb-12">
                  <v-row no-gutters class="justify-center">
                    <span
                      class="white--text font-weight-black px-5 py-1 blue"
                      style="font-size: 1.2rem; border-radius: 10px"
                    >
                      {{ $t('student.student_revise') }}
                    </span>
                  </v-row>
                  <v-card
                    class="rounded-xl mx-auto px-6 my-4 py-2"
                    :max-width="$vuetify.breakpoint.smAndUp ? '75%' : '95%'"
                  >
                    <v-alert
                      dense
                      text
                      color="warning"
                      class="
                        font-weight-black
                        rounded-lg
                        text-body-2 text-sm-subtitle-1
                        my-4
                      "
                      v-if="exercisesDone"
                    >
                      {{ $t('student.student_respostaenviada') }}
                    </v-alert>
                    <v-alert
                      dense
                      text
                      :color="checkResult[3]"
                      class="
                        font-weight-black
                        rounded-lg
                        text-body-2 text-sm-subtitle-1
                        my-4
                      "
                      v-if="allQuestions.length !== exercises.length"
                    >
                      {{ $t('student.student_respondafinalizar') }}
                    </v-alert>

                    <v-alert
                      dense
                      v-for="(n, index) in allQuestions"
                      :key="index"
                      text
                      :color="checkResult[0]"
                      class="
                        font-weight-black
                        rounded-lg
                        text-body-2 text-sm-subtitle-1
                        my-4
                      "
                    >
                      <v-row no-gutters class="text-center" justify="center">
                        {{ index + 1 }} -

                        <span v-if="n.type == 'M' || n.type == 'B'" class="ml-2">
                          {{ n.content }}
                        </span>
                        <v-img v-if="n.type == 'I'" :src="n.content" max-width="250px" class="ml-2"/>
                        <span v-if="n.type == 'D'" class="ml-2">
                          {{ n.answer }}
                        </span>

                      </v-row>
                    </v-alert>
                  </v-card>
                  <v-row justify="center" class="mt-6">
                    <v-col cols="10" sm="8" align="center">
                      <v-btn
                        @click="sendAnswers(allQuestions)"
                        dark
                        large
                        rounded
                        color="green"
                        :disabled="allQuestions.length !== exercises.length"
                      >
                        {{ $t('botoes.botao_inviarresposta') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-window-item>

              <!-- RESULT -->
              <v-window-item>
                <div class="pa-2 text-center">
                  <v-row no-gutters class="justify-center">
                    <span
                      class="white--text font-weight-black px-5 py-1 blue"
                      style="font-size: 1.2rem; border-radius: 10px"
                    >
                      {{ $t('student.student_resultado') }}
                    </span>
                  </v-row>
                  <v-card
                    class="rounded-xl mx-auto px-6 my-4 py-2"
                    :max-width="$vuetify.breakpoint.smAndUp ? '75%' : '95%'"
                  >
                    <v-alert
                      dense
                      v-for="(n, index) in allQuestions"
                      :key="index"
                      text
                      :color="
                        n.correct == true ? checkResult[1] : checkResult[2]
                      "
                      class="
                        font-weight-black
                        rounded-lg
                        text-body-2 text-sm-subtitle-1
                        my-4
                      "
                    >
                      <v-row no-gutters class="text-center" justify="center">
                        {{ index + 1 }} -

                        <span v-if="n.type == 'M' || n.type == 'B'" class="ml-2">
                          {{ n.content }}
                        </span>
                        <v-img v-if="n.type == 'I'" :src="n.content" max-width="250px" class="ml-2" />
                        <span v-if="n.type == 'D'" class="ml-2">
                          {{ n.answer }}
                        </span>

                      </v-row>
                    </v-alert>
                  </v-card>

                  <!-- <v-row justify="center" class="py-16">
                    <v-col cols="10" sm="5" md="4" align="center">
                      <v-btn
                        @click="tryAgain()"
                        rounded
                        dark
                        large
                        color="warning"
                        style="width: 200px"
                        :disabled="btnTryAgain"
                      >
                        Tentar Novamente
                      </v-btn>
                    </v-col>
                    <v-col cols="10" sm="5" md="4" align="center">
                      <v-btn
                        @click="finishExercise()"
                        rounded
                        dark
                        large
                        color="blue"
                        width="135px"
                        style="width: 200px"
                        :disabled="btnFinished"
                      >
                        Finalizar
                      </v-btn>
                    </v-col>
                  </v-row> -->
                </div>
              </v-window-item>

            </v-window>
          </v-tab-item>

          <!-- ATIVIDADES -->
          <v-tab-item v-if="hasActivity" value="6">
            <v-card flat class="backcolor">
              <v-row no-gutters class="justify-center">
                <span
                  class="white--text font-weight-black px-5 my-3 blue"
                  style="font-size: 1.2rem; border-radius: 10px"
                >
                  {{ $t('student.student_atividade') }}
                </span>
              </v-row>
              <v-row no-gutters class="justify-center pt-2">
                <v-col cols="10" md="7" class="mx-auto">
                  <v-img
                    src="https://nintendoboy.com.br/wp-content/uploads/2020/10/retroboy-Super-Bomberman.jpg"
                    class="mx-auto my-auto"
                    height="350px"
                  >
                  </v-img>
                </v-col>
                <v-col cols="10" md="7" class="mx-auto my-3">
                  <div><strong> {{ $t('student.student_nomejogo') }} </strong></div>
                  <span>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quas expedita hic eaque. Facere rem corrupti ipsam, alias
                    praesentium fugiat in cum unde aut inventore distinctio,
                    accusamus facilis labore. Voluptatum, fuga! Lorem ipsum
                    dolor sit amet consectetur adipisicing elit.
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>

        </v-tabs-items>
      </v-col>
    </v-row>
    <v-footer class="px-5 blue footer" height="90px" v-if="isExercise">
      <v-row no-gutters>
        <v-col cols="4" class="d-flex justify-start align-center">
          <v-btn
            class="rounded-xl"
            dark
            color="darkpink"
            :disabled="btnPrevDisable"
            @click="prevBtn()"
          >
            {{ $t('botoes.botao_voltar') }}
          </v-btn>
        </v-col>
        <v-col cols="4" class="d-flex justify-center align-center">
          <v-img src="../../assets/Question.svg" max-width="80px"></v-img>
        </v-col>
        <v-col cols="4" class="d-flex justify-end align-center">
          <v-btn
            class="rounded-xl"
            dark
            color="darkpink"
            :disabled="btnNextDisable"
            @click="endExercicie ? finishedClass() : nextBtn()"
          >
            <span v-if="endExercicie">{{ $t('botoes.botao_finalizar') }}</span>
            <span v-if="!endExercicie">{{ $t('botoes.botao_proximo') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <v-footer fixed class="px-5 blue" height="75px" v-if="footerOneTab">
      <v-row no-gutters>
        <v-col cols="4" class="d-flex justify-start align-center">
          <v-btn
            class="rounded-xl"
            dark
            color="darkpink"
            @click="backToClasses()"
          >
            {{ $t('botoes.botao_voltar') }}
          </v-btn>
        </v-col>
        <v-col cols="4" class="d-flex justify-center align-center">
          <v-img src="../../assets/Pencil.svg" max-width="75px"></v-img>
        </v-col>
        <v-col cols="4" class="d-flex justify-end align-center">
          <v-btn
            class="rounded-xl"
            dark
            color="darkpink"
            :disabled="btnOneTabFinish"
            @click="finishedClass()"
          >
            {{ $t('botoes.botao_finalizar') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const gpt_api = process.env.VUE_APP_GPT_ENDPOINT;
const gpt_token = process.env.VUE_APP_GPT_TOKEN;

export default {
  data() {
    return {
      selectSlide: '',
      bigslide: false,
      footerOneTab: false,
      isPortrait: false,
      onboarding: 0,
      slide: null,
      model: 0,
      tab: null,
      tabs: [],
      // btnTryAgain: false,
      btnFinished: false,
      presentationDisable: false,
      montagemDisable: true,
      resumoDisable: true,
      exerciciosDisable: true,
      atividadeDisable: true,
      tabResumo: true,
      statusResult: true,
      checkResult: ["blue", "green", "red", "warning"],
      idClass: this.$parent.$parent.$parent.exercisesByClassId,
      mounting: [],
      presentation: [],
      embed: [],
      hasEmbed: false,
      summaries: null,
      exercises: [],
      allQuestions: [],
      exercisesDone: false,
      videoID: null,
      classes: [],
      idStudent: [],
      hasPresentation: false,
      hasMounting: false,
      hasExercises: false,
      hasSummaries: false,
      hasActivity: false,
      isExercise: false,
      btnNextDisable: false,
      btnPrevDisable: true,
      playerReady: false,
      showTab: false,
      countPositionMounting: 0,
      btnOneTabFinish: false,
      endExercicie: false,
    };
  },
  created() {
    this.getExercises();
    this.getEmbed();
    this.getClassesData();
    this.verifyProgress();
    // this.getClasses();
    setTimeout(() => {
      this.verifyTab();
    }, 800);
  },
  methods: {
    finishedClass() {
      if (this.$parent.$parent.$parent.toclassOtherCourse) {
        this.$parent.$parent.$parent.toclassOtherCourse = false;
        this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu22');
      } else {
        this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu3');
      }
    },
    countAndVerifyMounting() {
      this.countPositionMounting++;
      if (this.countPositionMounting >= this.mounting.length) {
        this.btnOneTabFinish = false;
      }
    },
    backToClasses() {
      if (this.$parent.$parent.$parent.toclassOtherCourse) {
        this.$parent.$parent.$parent.toclassOtherCourse = false;
        this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu22');
      } else {
        this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu3');
      }
    },
    onReady() {
      this.playerReady = true;
    },
    nextBtn() {
      this.onboarding =
        this.onboarding + 1 === this.exercises.length + 2
          ? 0
          : this.onboarding + 1;
      if (this.onboarding == this.exercises.length) {
        this.btnNextDisable = true;
        this.checkOptions();
      }
      if (this.onboarding !== 0) {
        this.btnPrevDisable = false;
      }
      if (this.onboarding == this.exercises.length + 2 && this.endExercicie) {
        this.btnNextDisable = false;
      }
    },
    prevBtn() {
      this.onboarding =
        this.onboarding - 1 < 0
          ? this.exercises.length + 2 - 1
          : this.onboarding - 1;
      if (this.onboarding == this.exercises.length - 1) {
        this.btnNextDisable = false;
      }
      if (this.onboarding == 0) {
        this.btnPrevDisable = true;
      }
      if (this.onboarding == this.exercises.length) {
        this.btnNextDisable = true;
      }
      this.endExercicie = false;
    },
    checkTab(a) {
      if (a == 5) {
        this.isExercise = true;
      } else {
        this.isExercise = false;
      }
    },

    ampliarSlide() {
      // this.selectSlide = slide
      this.bigslide = true
    },
    verifyTab() {

      if (this.hasPresentation) {
        this.tabs.push({
          id: 1,
          title: this.$t('student.student_apresentacao.apresentacao1'),
          icon: "mdi-video-stabilization",
        });
      }

      if (this.hasMounting) {
        this.tabs.push({ id: 2, title: this.$t('student.student_apresentacao.apresentacao2'), icon: "mdi-toy-brick" });
        this.btnOneTabFinish = true;
      }
      if (this.hasSummaries) {
        this.tabs.push({
          id: 3,
          title: this.$t('student.student_apresentacao.apresentacao3'),
          icon: "mdi-book-open-page-variant",
        });
      }
      if (this.hasEmbed) {
        this.tabs.push({ id: 4, title: "conteudo adicional", icon: "mdi-gamepad" });
      }
      if (this.hasExercises) {
        this.tabs.push({ id: 5, title: this.$t('student.student_apresentacao.apresentacao4'), icon: "mdi-head-cog" });
        if (
          !this.hasPresentation &&
          !this.hasMounting &&
          !this.hasPresentation
        ) {
          this.isExercise = true;
        }
      }
      if (this.hasActivity) {
        this.tabs.push({ id: 6, title: this.$t('student.student_apresentacao.apresentacao5'), icon: "mdi-gamepad" });
      }

      if (this.tabs.length == 1) {
        if (!this.isExercise) {
          this.footerOneTab = true;
        }
        this.showTab = false;
        this.btnFinished = true;
      } else {
        this.showTab = true;
      }
    },
    getClasses() {
      axios
        .get(`${url}/classesForStudent`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.classes = res.data;
          this.idStudent = this.classes[0].idstudent;
          this.verifyProgress(this.idStudent);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async verifyProgress() {
      let data = await axios.get(
        `${url}/progressLessonsForStudent?classes=${this.idClass}`,
        {
          headers: {
            authorization: token,
          },
        }
      );
      this.exercisesDone = data.data[0].exercise;
    },
    async getClassesData() {
      try {
        let mount = await axios.get(
          `${url}/lessonsMountings/byClass/${this.idClass}`
        );
        if (mount.data.length !== 0) {
          this.hasMounting = true;

          for (let i = 0; i < mount.data.length; i++) {
            const element = mount.data[i].uri;
            this.mounting.push(element);
            this.isPortrait = mount.data[0].isPortrait;
          }
        } else {
          this.hasMounting = false;
        }

        let present = await axios.get(
          `${url}/lessonsPresentations/byClass/${this.idClass}`
        );

        if (present.data.length !== 0) {
          this.presentation = present.data;

          this.hasPresentation = true;
        } else {
          this.hasPresentation = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getEmbed() {
      try {
        let exerc = await axios.get(
          `${url}/embed/${this.idClass}`,
          {
            headers: {
              authorization: token,
            },
          }
        );
        if (exerc.data.length === 0) {
          this.hasEmbed = false;
        } else {
          this.hasEmbed = true;
          this.embed = exerc.data[0].embed;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getExercises() {
      try {
        let exerc = await axios.get(
          `${url}/exercisesByClass?lessons=${this.idClass}`,
          {
            headers: {
              authorization: token,
            },
          }
        );
        this.exercises = exerc.data;
        if (this.exercises.length === 0) {
          this.hasExercises = false;
        } else {
          this.hasExercises = true;
        }
      } catch (error) {
        console.error(error);
      }

      try {
        let summ = await axios.get(`${url}/summaries?lessons=${this.idClass}`, {
          headers: {
            authorization: token,
          },
        });

        if (summ.data.length !== 0) {
          this.summaries = summ.data[0].uri;
          this.hasSummaries = true;
        } else {
          this.hasSummaries = false;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async sendAnswers(data) {
      if (this.exercisesDone === false) {
        let correctCount = 0;
        for (const iterator of data) {
          try {
            await axios.post(`${url}/studentAnswers`, iterator, {
              headers: {
                authorization: token,
              },
            });
          } catch (error) {
            console.error(error);
          }

          if (iterator.correct) {
            correctCount++;
          }
        }
        let result = (correctCount / data.length) * 10;
        let body = {
          classId: this.idClass,
          grade: result,
        };

        try {
          await axios.post(`${url}/grades`, body, {
            headers: { authorization: token },
          });
        } catch (error) {
          console.error(error);
        }
      }

      this.onboarding =
        this.onboarding + 1 === this.exercises.length + 2
          ? 0
          : this.onboarding + 1;
      this.btnNextDisable = true;
      this.btnPrevDisable = true;

      this.finishExercise();
    },

    async finishExercise() {
      let body = {
        classId: this.idClass,
        exercise: true,
      };
      await axios.put(`${url}/progressLessonsForStudent`, body, {
        headers: {
          authorization: token,
        },
      });
      this.endExercicie = true;
      setTimeout(() => {
        this.btnNextDisable = false;
        this.btnPrevDisable = false;
      }, 1000);
    },

    async checkReview() {
      let body = {
        classId: this.idClass,
        review: true,
        studentId: this.idStudent,
      };
      await axios.put(`${url}/progressLessons`, body, {
        headers: {
          authorization: token,
        },
      });
    },
    async toCourses() {
      let body = {
        classId: this.idClass,
        activity: true,
        studentId: this.idStudent,
      };
      await axios.put(`${url}/progressLessons`, body, {
        headers: {
          authorization: token,
        },
      });
      this.$emit("message", this.$t('admin.admin_menu.menu3'));
      this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu3');
    },
    multiSelected(opt, quest, indexExe, escrita) {
      this.allQuestions[indexExe] = { exerciseId: quest, optionId: opt, answer: escrita };
    },

    async checkOptions() {
      try {
        for (let element of this.exercises) {
          for (let i = 0; i < this.allQuestions.length; i++) {
            let elem = this.allQuestions[i];
            if (element.id === elem.exerciseId) {
              for (let e of element.option) {
                if (e.id === elem.optionId) {
                  elem.type = element.type;
                  elem.content = e.content;
                  elem.correct = e.correct;

                  if (elem.answer !== null) {
                    const correto = await this.verificaRespostaDissertativa(elem.content, elem.answer);
                    elem.correct = correto;
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        console.error('Erro ao verificar opções:', error);
      }
    },

    async verificaRespostaDissertativa(respostaEsperada, respostaAluno) {
      try {
        const response = await axios.post(`${gpt_api}`, {
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'user',
              content: `Responda apenas com CORRETO ou ERRADO se a Resposta condiz com a resposta esperada, ou seja, se a pessoa respondeu dentro do esperado - Resposta: '${respostaAluno}', Resposta Esperada: '${respostaEsperada}'`,
            },
          ],
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${gpt_token}`,
          },
        });

        const respostaGPT = response.data.choices[0].message.content;
        return respostaGPT.includes('CORRETO');
      } catch (error) {
        console.error('Erro na requisição ao ChatGPT:', error);
        // Em caso de erro, retorne true ou false conforme necessário
        return false; // ou true, dependendo do comportamento desejado
      }
    },


  },

};
</script>

<style lang="css" scoped>
.selected {
  border: 6px solid #1670e8;
  transition: all 0.5s;
}

.unselected {
  border: 6px solid rgba(22, 113, 232, 0.173);
  transform: none;
  transition: all 0.5s;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.cursor-lupa {
  cursor: zoom-in; /* Isso usa o cursor padrão de lupa do sistema */
}
</style>
