<template>
  <!-- ATIVIDADES/JOGOS -->
  <div class="backcolor" style="height: 100vh">
    <v-row v-if="!gamefication">
      <v-col
        cols="12"
        md="10"
        v-if="$vuetify.breakpoint.mdAndUp || !achivementsOn"
        class="mx-auto"
      >
        <v-row class="mt-4" justify="center" align="center">
          <span
            class="blue font-weight-bold px-10 my-2 white--text"
            style="
              font-size: 1.3rem;
              border-radius: 15px;
              text-transform: uppercase;
            "
          >
            {{ $t('admin.admin_menu.menu19') }}
          </span>
        </v-row>
        <v-row class="mx-2">
          <v-col v-for="game in games" :key="game.id" cols="12" sm="6">
            <v-card class="mx-auto darkblue" dark>
              <v-img max-height="250" :src="game.image"></v-img>

              <v-card-title
                :style="$vuetify.breakpoint.mdAndUp ? '' : 'font-size: 1rem'"
              >
                {{ game.name }}

                <v-spacer> </v-spacer>
                <v-btn
                  color="darkpink px-3"
                  rounded
                  dark
                  @click="chooseGame(game.url)"
                  target="_blank"
                >
                  {{ $t('botoes.botao_jogar') }}
                </v-btn>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          @click="achivementsOn = true"
          fab
          bottom
          right
          fixed
          dark
          class="yellow"
        >
          <v-icon large>mdi-trophy-award</v-icon>
        </v-btn> -->
      </v-col>

      <!-- <v-col
        cols="12"
        md="3"
        class="text-center"
        :class="$vuetify.breakpoint.mdAndUp ? 'blue' : 'backcolor'"
        v-if="$vuetify.breakpoint.mdAndUp || achivementsOn"
      >
        <v-card class="darkblue ma-4 pa-4">
          <div
            class="white font-weight-bold px-5 my-2 blue--text"
            style="
              font-size: 1.2rem;
              border-radius: 10px;
              text-transform: uppercase;
            "
          >
            Conquistas
          </div>
          <v-row class="mt-2">
            <v-col
              cols="3"
              v-for="achivement in achivements"
              :key="achivement.id"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar class="ma-1" size="45" tile>
                    <v-img
                      contain
                      v-bind="attrs"
                      v-on="on"
                      :src="achivement.icon"
                      :class="achivement.active ? '' : 'conquista'"
                    >
                    </v-img>
                  </v-avatar>
                </template>
                <span>{{ achivement.text }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="darkblue ma-4 pa-4">
          <div
            class="white font-weight-bold px-3 my-2 blue--text"
            style="
              font-size: 1.2rem;
              border-radius: 10px;
              text-transform: uppercase;
            "
          >
            Melhor Desempenho
          </div>
          <div style="font-size: 1.2rem" class="mt-6 white--text">
            Nome do Jogo
          </div>
          <div style="font-size: 2.2rem" class="white--text">00000</div>
          <v-img
            src="../../assets/HuntAllien.svg"
            class="mx-auto"
            width="10rem"
          ></v-img>
        </v-card>
        <v-card class="darkblue ma-4 pa-4" style="border-radius: 10px">
          <v-list-item dark>
            <v-list-item-avatar
              v-if="$vuetify.breakpoint.lgAndUp"
              size="35%"
              style="border: 3px solid #ffffff"
            >
              <v-img :src="studentInfo.avatar"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h6"> Level 0 </v-list-item-title>
              <span class="text-body-2"
                >Parabéns {{ studentInfo.student.name }}! Continue jogando para
                aumentar o seu nível!</span
              >
              <v-progress-linear
                color="yellow"
                height="15"
                value="75"
                striped
                class="mt-2"
                rounded
              >
              </v-progress-linear>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          @click="achivementsOn = false"
          fab
          bottom
          right
          fixed
          dark
          class="yellow"
        >
          <v-icon large>mdi-arrow-left-bottom</v-icon>
        </v-btn>
      </v-col> -->
    </v-row>
    <v-row v-if="gamefication" no-gutters>
      <v-col
        cols="12"
        sm="11"
        md="10"
        class="mx-auto d-flex justify-center pa-0 mt-4 mt-sm-6 mt-md-8 mt-lg-14"
      >
        <iframe
          :src="selectedGame"
          frameborder="0"
          style="border: 5px solid #1670e8"
          :style="
            $vuetify.breakpoint.xsOnly
              ? 'width: 90vw; height: 50vh;'
              : 'width: 50vw; height: 50vh;'
          "
          class="rounded-lg"
        ></iframe>
      </v-col>
      <v-btn
        fixed
        left
        fab
        absolute
        :x-large="!$vuetify.breakpoint.xsOnly"
        color="darkpink"
        style="bottom: 30px; left: 30px"
        class="elevation-10"
        @click="leaveGame()"
      >
        <v-icon color="white">mdi-arrow-left</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gamefication: false,
      selectedGame: null,
      games: [
        {
          id: 1,
          name: "Goal Keeper",
          image:
            "http://www.makereducacional.com.br/astronaut/gameevent/Goalkeeper.svg",
          url: "https://myrobotlab.com.br/games/gamefication/futebola/",
        },
        {
          id: 2,
          name: "Sushi Bar",
          image:
            "http://www.makereducacional.com.br/astronaut/gameevent/SushiBar.svg",
          url: "https://myrobotlab.com.br/games/gamefication/sushi/",
        },
        {
          id: 3,
          name: "Sky Conqueror",
          image:
            "http://www.makereducacional.com.br/astronaut/gameevent/SkyConqueror.svg",
          url: "https://myrobotlab.com.br/games/gamefication/plane/",
        },
        {
          id: 4,
          name: "Gravity Cannon",
          image:
            "http://www.makereducacional.com.br/astronaut/gameevent/GravityCannon.svg",
          url: "https://myrobotlab.com.br/games/gamefication/cannon/",
        },
      ],
      achivementsOn: false,
      achivements: [
        {
          id: 1,
          icon: "http://www.makereducacional.com.br/astronaut/gameevent/QuestionMark.svg",
          text: "Em Breve",
          active: true,
        },
        {
          id: 2,
          icon: "http://www.makereducacional.com.br/astronaut/gameevent/QuestionMark.svg",
          text: "Em Breve",
          active: false,
        },
        {
          id: 3,
          icon: "http://www.makereducacional.com.br/astronaut/gameevent/QuestionMark.svg",
          text: "Em Breve",
          active: false,
        },
        {
          id: 4,
          icon: "http://www.makereducacional.com.br/astronaut/gameevent/QuestionMark.svg",
          text: "Em Breve",
          active: false,
        },
        {
          id: 5,
          icon: "http://www.makereducacional.com.br/astronaut/gameevent/QuestionMark.svg",
          text: "Em Breve",
          active: false,
        },
        {
          id: 6,
          icon: "http://www.makereducacional.com.br/astronaut/gameevent/QuestionMark.svg",
          text: "Em Breve",
          active: false,
        },
        {
          id: 7,
          icon: "http://www.makereducacional.com.br/astronaut/gameevent/QuestionMark.svg",
          text: "Em Breve",
          active: false,
        },
        {
          id: 8,
          icon: "http://www.makereducacional.com.br/astronaut/gameevent/QuestionMark.svg",
          text: "Descrição da Conquista",
          active: false,
        },
      ],
      studentInfo: null,
    };
  },
  created() {
    this.studentInfo = this.$parent.$parent.$parent.profile;
  },
  methods: {
    chooseGame(game) {
      this.selectedGame = game;
      this.gamefication = true;
    },
    leaveGame() {
      this.selectedGame = null;
      this.gamefication = false;
    },
  },
};
</script>

<style lang="css" scoped>
.conquista {
  filter: grayscale(100%);
  opacity: 60%;
}
</style>
