import { render, staticRenderFns } from "./StudentProfile.vue?vue&type=template&id=3e7cc760&scoped=true&"
import script from "./StudentProfile.vue?vue&type=script&lang=js&"
export * from "./StudentProfile.vue?vue&type=script&lang=js&"
import style0 from "./StudentProfile.vue?vue&type=style&index=0&id=3e7cc760&lang=css&scoped=true&"
import style1 from "./StudentProfile.vue?vue&type=style&index=1&id=3e7cc760&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e7cc760",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VApp,VAvatar,VBtn,VCard,VCardText,VCardTitle,VCol,VDialog,VIcon,VImg,VListItem,VListItemAvatar,VListItemContent,VRow,VSheet,VSpacer,VTooltip})
