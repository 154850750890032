<template>
  <v-app class="backcolor">
    <v-row no-gutters>
      <v-col cols="11" lg="8" class="mx-auto my-6">
        <v-card class="rounded-xl pa-2 py-6 pa-sm-6" elevation="15">
          <v-row no-gutters justify="center" align="center">
            <span
              class="blue font-weight-medium px-6 mb-4 white--text"
              style="
                font-size: 1.3rem;
                border-radius: 15px;
                text-transform: uppercase;
              "
            >
              {{ $t('student.student_outrocurso') }}
            </span>
          </v-row>
          <v-row class="mx-2">
            <v-col
              v-for="course in coursesQuery"
              :key="course.id"
              cols="12"
              md="6"
            >
              <v-hover v-slot="{ hover }" open-delay="750">
                <v-card
                  class="mx-auto"
                  color="darkblue rounded-xl"
                  elevation="5"
                >
                  <v-img
                    :max-height="$vuetify.breakpoint.smAndUp ? 200 : 160"
                    :src="course.thumbnail"
                  >
                    <v-fade-transition>
                      <div
                        v-if="hover"
                        class="
                          d-flex
                          transition-fast-in-fast-out
                          grey
                          darken-4
                          v-card--reveal
                          white--text
                          px-4
                        "
                        style="height: 100%"
                      >
                        {{ course.description }}
                      </div>
                    </v-fade-transition>
                  </v-img>
                  <v-row
                    no-gutters
                    justify="center"
                    align="center"
                    class="py-2"
                  >
                    <v-col cols="12">
                      <div
                        class="
                          white--text
                          py-1
                          px-6
                          font-weight-bold
                          text-subtitle-1 text-sm-h6 text-center
                        "
                      >
                        {{ course.name }}
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-card-actions class="align-center mx-2 justify-center">
                        <v-btn
                          color="darkpink px-4 rounded-xl"
                          dark
                          @click="toCourseClass(course)"
                        >
                          {{ $t('botoes.botao_iniciacurso') }}
                        </v-btn>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                </v-card>
              </v-hover>
            </v-col>

            <v-col
              v-if="hasCourses === 0"
              cols="12"
              sm="11"
              class="mx-auto"
              align-self="center"
            >
              <v-img
                src="../../assets/Science.svg"
                :max-width="$vuetify.breakpoint.smAndUp ? '40%' : '100%'"
                class="mx-auto"
              ></v-img>
              <div class="text-h5 text-sm-h4 text-center font-weight-medium">
                {{ $t('alerta.alerta_nenhumcurso') }}
              </div>
              <div
                class="
                  text-sm-h6
                  px-sm-16
                  text-center
                  font-weight-normal
                  text--secondary
                "
              >
                {{ $t('alerta.alerta_recarregue') }}
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");

export default {
  data() {
    return {
      coursesQuery: [],
      hasCourses: null,
      profileData: [],
    };
  },
  mounted() {
    setTimeout(() => {
      this.profileData = this.$parent.$parent.$parent.profile;
      let courseId = this.profileData.student.team.courseId;
      this.getCourses(courseId);
    }, 500);
  },
  methods: {
    async addCourseOfStudent(course) {
      if (course.sequence < 12 || course.sequence > 16) {
        try {
          await axios.post(
            `${url}/coursesOfStudents/`,
            { course: course.id },
            {
              headers: {
                authorization: token,
              },
            }
          );
        } catch (error) {
          console.error(error);
        }
      }
    },
    toCourseClass(course) {
      this.$parent.$parent.$parent.courseId = course.id;
      this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu22');
      this.addCourseOfStudent(course);
    },
    getCourses(courseId) {
      axios
        .get(`${url}/coursesForStudent/${courseId}`, {
          headers: {
            authorization: token,
          },
        })
        .then((response) => {
          this.hasCourses = response.data.length;
          this.coursesQuery = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style style lang="css" scoped>
.float-img {
  position: absolute;
}

.center-img {
  position: absolute;
  right: 6%;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.85;
  position: absolute;
  width: 100%;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncateSm {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
