import { render, staticRenderFns } from "./StudentOtherCourse.vue?vue&type=template&id=8c65abce&scoped=true&"
import script from "./StudentOtherCourse.vue?vue&type=script&lang=js&"
export * from "./StudentOtherCourse.vue?vue&type=script&lang=js&"
import style0 from "./StudentOtherCourse.vue?vue&type=style&index=0&id=8c65abce&style=true&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c65abce",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VBtn,VCard,VCardActions,VCol,VFadeTransition,VHover,VImg,VRow})
